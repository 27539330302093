import homeBannerSliderItem1 from "../assets/img/home-page/home-banner-slider-item-1.webp";
import homeBannerSliderItem2 from "../assets/img/home-page/home-banner-slider-item-2.webp";
import homeBannerSliderItem3 from "../assets/img/home-page/home-banner-slider-item-3.webp";
import homeBannerSliderItem4 from "../assets/img/home-page/home-banner-slider-item-4.webp";
import homeBannerSliderItem5 from "../assets/img/home-page/home-banner-slider-item-5.webp";
import homeBannerSliderItem6 from "../assets/img/home-page/home-banner-slider-item-6.webp";
import homeBannerSliderItem7 from "../assets/img/home-page/home-banner-slider-item-7.webp";
import homeBannerSliderItem8 from "../assets/img/home-page/home-banner-slider-item-8.webp";
import homeBannerSliderItem9 from "../assets/img/home-page/home-banner-slider-item-9.webp";
import homeBannerSliderItem10 from "../assets/img/home-page/home-banner-slider-item-10.webp";
import homeBannerSliderItem11 from "../assets/img/home-page/home-banner-slider-item-11.webp";

export const HomeBannerSliderItems = [
    {
        imageSrc: homeBannerSliderItem1,
        imageAlt: 'Home Banner Slider Item 1',
        active: true
    },
    {
        imageSrc: homeBannerSliderItem2,
        imageAlt: 'Home Banner Slider Item 2',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem3,
        imageAlt: 'Home Banner Slider Item 3',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem4,
        imageAlt: 'Home Banner Slider Item 4',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem5,
        imageAlt: 'Home Banner Slider Item 5',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem6,
        imageAlt: 'Home Banner Slider Item 6',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem7,
        imageAlt: 'Home Banner Slider Item 7',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem8,
        imageAlt: 'Home Banner Slider Item 8',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem9,
        imageAlt: 'Home Banner Slider Item 9',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem10,
        imageAlt: 'Home Banner Slider Item 10',
        active: false
    },
    {
        imageSrc: homeBannerSliderItem11,
        imageAlt: 'Home Banner Slider Item 11',
        active: false
    },
];