import './HomeBannerSlider.scss'
import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function HomeBannerSlider (props) {
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        const active = props.items.find( i => i.active);
        
        setActiveItem(active);
    }, []);

    const changeActiveItem = (index) => {
        const activeItem = props.items.find( i => i.active);
        if (activeItem) {
            activeItem.active = false;
        }
        props.items[index].active = true;

        setActiveItem(props.items[index]);
    }
    
    const nextItem = () => {
        const currentIndex = props.items.findIndex(i => i.active);
        
        if (currentIndex === props.items.length - 1) {
            changeActiveItem(0);
        } else {
            changeActiveItem(currentIndex + 1);
        }
    }
    
    const previousItem = () => {
        const currentIndex = props.items.findIndex(i => i.active);
        
        if (currentIndex === 0) {
            changeActiveItem(props.items.length - 1);
        } else {
            changeActiveItem(currentIndex - 1);
        }
    }
    
    return (
        <div className={'home-banner-slider'}>
            <div className={'navigation-button button-previous'} title={'Previous'} onClick={() => previousItem()}>
                <LeftOutlined />
            </div>
            <div className={'slider-image-item'}>
                {activeItem && <img src={activeItem.imageSrc} alt={activeItem.imageAlt}/>}
            </div>
            <div className={'navigation-button button-next'} title={'Next'} onClick={() => nextItem()}>
                <RightOutlined />
            </div>
            <div className={'slider-navigation-bar'}>
                {props.items.map((item, index) => {
                    return <div key={index}
                                className={[
                                    'navigation-bar-item',
                                    item.active ? 'active' : '',
                                ].join(' ')}
                                onClick={() => changeActiveItem(index)}
                    ></div>
                })}
            </div>
        </div>
    );
}

export default HomeBannerSlider;