export const navigationItems = [
    {
        id: 'about',
        title: 'About',
        link: '/about'
    },
    {
        id: 'training',
        title: 'Training',
        link: '/training'
    },
    {
        id: 'order',
        title: 'Order',
        link: '/order'
    },
    {
        id: 'support',
        title: 'Support',
        link: '/support'
    }
];