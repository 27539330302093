import React from 'react';

import './Order-page.scss';
import readinessFormPdf from "../../assets/doc/PF-Kiosk-Club-Readiness-Form.pdf";
import vickiKioskPricing from "../../assets/img/order-page/vicki-kiosk-pricing.png";
import vickiMachineNexStepsPF from "../../assets/doc/Vicki-Machine-Next-Steps-PF.docx";

function OrderPage(props) {
    const receiverEmails = ['vickipfsupport@viatouchmedia.com'];
    return (
        <div className={'order-page-wrapper'}>
            <section className={'section'}>
                <h2 className={'section-title'}>Readiness form</h2>
                <ol className={'list-wrapper body-text'}>
                    <li>
                        Complete Readiness Form –
                        <a href={readinessFormPdf} download>download Readiness Form</a>
                    </li>
                    <li>
                        Attach readiness form
                        <a href={"mailto:" + receiverEmails.join(',') + "?subject=PF Kiosk Club Readiness Form&body=Hello!%0D%0A%0D%0A%0D%0AAttached is the completed PF Kiosk Club Readiness Form for your reference.%0D%0A%0D%0A%0D%0AThanks,%0D%0APlanet Fitness"}>Here</a>
                    </li>
                    <li>
                        Include a target date for delivery of units in e-mail – 6-8 weeks from order
                        <ul className={'mt-10'}>
                            <li>We recommend that this is close to opening</li>
                            <li>
                                Please download and read
                                <a href={vickiMachineNexStepsPF} download>Machine next steps document</a>
                            </li>
                        </ul>
                    </li>
                    <li>ViaTouch media will populate a quote & send back & confirm date for delivery of units</li>
                    <li>Franchise group needs to complete invoicing information & sign and return</li>
                    <li>
                        Once we are a few weeks out from delivery, ViaTouch will be in touch to confirm the date and
                        schedule the install/setup and training.
                    </li>
                </ol>

                <div className={'vicki-kiosk-pricing-wrapper'}>
                    <img src={vickiKioskPricing} alt={'Vicki Kiosk Pricing'}/>
                </div>
                {/*<hr className={'divider'}/>*/}
            </section>
            {/*<section className={'section'}>*/}
            {/*    <h2 className={'section-title'}>Readiness form</h2>*/}
            
            {/*    <ReadinessForm></ReadinessForm>*/}
            
            {/*    <hr className={'divider'}/>*/}
            {/*</section>*/}
            
            {/*<section className={'section'}>*/}
            {/*    <h2 className={'section-title'}>Order form</h2>*/}
            
            {/*    <OrderForm></OrderForm>*/}
            {/*</section>*/}
        </div>
    );
}

export default OrderPage;
