import React from 'react';

import './Vicki-page.scss';
import PdfViewer from "../../components/pdf-viewer/PdfViewer";
import beverageMachinePlanogram from "../../assets/img/vicki-page/beverage-machine.png";
import sundriesMachinePlanogram from "../../assets/img/vicki-page/sundries-machine.png";
import tanningMachinePlanogram from "../../assets/img/vicki-page/tanning-machine.png";
import vickiOverviewPdf from "../../assets/pdf/vicki-page/vicki-overview-2024.pdf";

function VickiPage(props) {
    return (
        <div className={'vicki-page-wrapper'}>
            <section className={'section'}>
                <h2 className={'section-title'}>Vicki PDF</h2>

                <div className={'vicki-pdf-wrapper'}>
                    <PdfViewer file={vickiOverviewPdf} />
                </div>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Product Specs</h2>

                <div className={'body-text text-left d-flex'}>
                    <ul className={'product-spec-list'}>
                        <li>Limited footprint: 35.7″W x 79.65″H x 32.24″D (door closed) or 59.50″D (door open)</li>
                        <li>Adjustable shelving for customized POGs by VICKI system by location if desired. Visual
                            opening
                            space: 25.50″W x 48″H x 20.125″D, however ~2.5″ of space is left between the back of the
                            tray
                            and the inside back wall grid. Shelves are easily adjusted.</li>
                        <li>IntellishelfTM units are generally 1X and 2X sizes. They can be arranged in a variety
                            of combinations providing layout flexibility with good pack out depending upon the SKU.
                            Shelves can be programmed to light up with full RGB range of colors.</li>
                        <li>Refrigerant R290 / 3.17 oz. [90g]. Cools to 34˚F.</li>
                        <li>Standard U.S. cellular communication with point-to-point encryption</li>
                        <li>Approximate weight is 850 lbs.</li>
                        <li>Uses standard 3-prong 120v AC plug</li>
                    </ul>
                </div>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Planogram specs</h2>

                <h3 className={'section-sub-title'}>Beverage Machine</h3>

                <div className={'planogram-photo-wrapper'}><img src={beverageMachinePlanogram} alt={'Beverage Machine'} /></div>

                <h3 className={'section-sub-title mt-50'}>Sundries Machine</h3>

                <div className={'planogram-photo-wrapper'}><img src={sundriesMachinePlanogram} alt={'Sundries Machine'} /></div>

                <h3 className={'section-sub-title mt-50'}>Tanning Machine</h3>

                <div className={'planogram-photo-wrapper'}><img src={tanningMachinePlanogram} alt={'Tanning Machine'} /></div>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>FAQ</h2>

                <p className={'body-text'}>To be done...</p>
            </section>
        </div>
    );
}

export default VickiPage;
