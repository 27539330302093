import React from 'react';

import './Home.scss';
import viatouchLogo from "../../assets/img/viatouch-logo.png";
import HomeBannerSlider from "../../components/home-banner-slider/HomeBannerSlider";
import { HomeBannerSliderItems } from "../../constants/home-banner-slider-items";

function Home(props) {
    return (
        <div className={'home-page-wrapper'}>
            <div className={'home-banner'}>
                <div className="right-part">
                    <div className={'logo'}>
                        <img src={viatouchLogo} alt="Viatouch Logo"/>
                    </div>

                    <div className={'home-banner-title'}>
                        Smart. Secure. Customizable.
                        <br/>
                        <br/>
                        VICKI is your next step into the future of auto-retailing.
                    </div>
                </div>
                <div className="left-part">
                    <HomeBannerSlider items={HomeBannerSliderItems} />
                </div>
            </div>
        </div>
    );
}

export default Home;
