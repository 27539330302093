import './Header.scss'
import viatouchLogo from "./../../assets/img/viatouch-logo.png";
import planetFitnessLogo from "./../../assets/img/planet-fitness-logo.webp";
import menuIcon from "./../../assets/img/menu-icon.svg";
import { NavLink } from "react-router-dom";
import { navigationItems } from "../../constants/navigation-items";

function Header(props) {
    return (
        <div className={'header'}>
            <div className={'logo'}>
                <NavLink to="/"
                         onClick={() => props.onMobileNavOpen(false)}>
                    <img src={planetFitnessLogo} alt="Viatouch Logo"/>
                </NavLink>
            </div>
            <div className={'navigation-links'}>
                <ul>
                    {navigationItems.map(item => (
                        <li key={item.id}>
                            <NavLink to={item.link}>{item.title}</NavLink>
                            <div className={'border-bottom'}></div>
                        </li>
                    ))}
                </ul>

            </div>
            <div className={'hamburger-button'}
                 onClick={() => props.onMobileNavOpen(!props.showMobileNav)}>
                <img src={menuIcon} alt="Menu"/>
            </div>
            
            <div className={[
                'mobile-menu',
                props.showMobileNav ? 'opened' : ''
            ].join(' ')}>
                <ul>
                    {navigationItems.map(item => (
                        <li key={item.id}>
                            <NavLink to={item.link}
                                     onClick={() => props.onMobileNavOpen(false)}>
                                {item.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Header;